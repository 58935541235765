import oRequest from '@/libs/request.js';
const URL = {
	login: 'admin/login/checkUser', //登录
	logOut: 'admin/login/logOut', //登录
}
export default {
	login(data = {}) {
		return new Promise((resolve, recject) => {
			oRequest.getAjax(URL.login, data).then(res => {
				window.localStorage.setItem('token',res.token);
				window.localStorage.setItem('tokenkey',res.tokenkey);
				resolve(res);
			})
		})
	},
	Logout(data = {}) {
		return new Promise((resolve, recject) => {
			oRequest.getAjax(URL.logOut, data).then(res => {
				//清理一切缓存
				window.localStorage.clear();
				window.sessionStorage.clear();
				// Watermark.set('')
				resolve(res);
			})
		})
	},

}
