<template>
  <div style="padding: 20px 0;">
    <!-- 面包屑开始 -->
    <!-- <div style="color:#a6a6a6;font-size:20px;font-weight:700;margin-bottom:10px;margin-top: 10px;">{{arrBreadData.title}}</div> -->
    <el-breadcrumb separator="/" >
      <el-breadcrumb-item :to="{ path: '/' }"> 首页 </el-breadcrumb-item>
      <el-breadcrumb-item
        v-for="(item, index) in arrBreadData.children"
        :key="index"
        :to="item.path ? { path: `${item.path}` } : ''"
      >
        {{ item.title }}
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  props: {
    arrBreadData: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {};
  },
};
</script>

<style>

</style>