import oRequest from '@/libs/request.js';
const URL = {
	getUserInfo: 'admin/SysUser/getUserInfo', //获取用户信息
	getMenuList: 'admin/sysMenu/sysMenuList', //获取菜单
	clearCache : 'admin/main/cleanCache', //清理缓存
	changePassword: 'admin/SysUser/changePassword', 
}
export default {
	changePassword(data = {}) {
		return new Promise((resolve, recject) => {
			oRequest.getAjax(URL.changePassword, data).then(res => {
				resolve(res);
			})
		})
	},
	getUserInfo(data = {}) {
		return new Promise((resolve, recject) => {
			oRequest.getAjax(URL.getUserInfo, data).then(res => {
				window.localStorage.setItem('user_info',JSON.stringify(res));
				resolve(res);
			})
		})
	},
	getMenuList(data = {}) {
		return new Promise((resolve, recject) => {
			oRequest.getAjax(URL.getMenuList, data).then(res => {
				resolve(res);
			})
		})
	},
	clearCache(data = {}) {
		return new Promise((resolve, recject) => {
			oRequest.getAjax(URL.clearCache, data).then(res => {
				resolve(res);
			})
		})
	},

}
