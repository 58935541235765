<template>
	<el-container class="home">
		<el-aside :width="isCollapse ? '65px' : '13%'" style="background-color: #233646">
			<div @click="toHome" style="cursor:pointer;">
				<div v-if="!isCollapse" style="
				  height: 80px;
				  color: #ffffff;
				  font-size: 20px;
				  font-weight: 700;
				  margin-bottom: 10px;
				  margin-top: 10px;
				  display: flex;
				  align-items: center;
				  justify-content: center;
				">
					客户管理
				</div>
				<div v-else style="
				  height: 80px;
				  color: #ffffff;
				  font-size: 20px;
				  font-weight: 700;
				  margin-bottom: 10px;
				  margin-top: 10px;
				  display: flex;
				  align-items: center;
				  justify-content: center;
				">
					<el-image style="width: 50px; height: 50px" :src="logo">
					</el-image>
				</div>
			</div>

			<!-- 侧边菜单开始 -->
			<el-menu router unique-opened :default-active="menuPath" background-color="#233646" text-color="#fff"
				active-text-color="	#409EFF" :collapse="isCollapse" :collapse-transition="false">
				<div v-for="val in menuList" :key="val.id">
					<el-submenu :index=String(val.link) v-if="val.children.length">
						<template slot="title">
							<i :class="val.icon"></i>
							<span>{{ val.name }}</span>
						</template>
						<!-- 二级菜单 -->
						<div v-for="v in val.children" :key="v.id">
							<el-menu-item :index="v.link" v-if="!v.children.length" @click="subMenu(v.link)">
								{{ v.name }}
							</el-menu-item>
							<el-submenu :index="v.link" v-if="v.children.length">
								<template slot="title">
									<i :class="v.icon"></i>

									<span>{{ v.name }}</span>
								</template>
								<el-menu-item :index="item.link" v-for="item in v.children" :key="item.id"
									@click="subMenu(item.link)">
									{{ item.name }}
								</el-menu-item>
							</el-submenu>
						</div>
					</el-submenu>
				</div>
			</el-menu>
			<!-- 侧边菜单结束 -->
		</el-aside>
		<el-container>
			<el-header style="height: 50px">
				<div>
					<el-row :gutter="20">
						<!-- 面包屑开始 -->
						<el-col :span="10">
							<Bread :arrBreadData="arrBreadData"></Bread>
						</el-col>
						<!-- 面包屑结束 -->
						<el-col :span="10" :push="4" style="margin-left: -20px">
							<div class="headerTop">
								<el-avatar src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fitem%2F201905%2F17%2F20190517211730_kurtr.thumb.1000_0.jpg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1648374076&t=96dfb5c6a28c43fb054965a3bc6dc59e" class="el-dropdown-link"
									style="margin-top: 5px">
								</el-avatar>
								<el-dropdown style="margin-top: 20px; margin-left: 2px" @command="handleCommand">
									<span class="el-dropdown-link">
										<i class="el-icon-arrow-down el-icon--right"></i>
									</span>
									<el-dropdown-menu slot="dropdown">
										<el-dropdown-item command="">{{oUserInfo.nickname}}</el-dropdown-item>
										<el-dropdown-item command="" icon="iconfont icon-bussiness-man">个人中心</el-dropdown-item>
										<el-dropdown-item command="clearCache" icon="iconfont icon-bussiness-man">清除缓存</el-dropdown-item>
										<el-dropdown-item command="password" icon="iconfont icon-password">修改密码</el-dropdown-item>
										<el-dropdown-item command="logout" icon="iconfont icon-RFQ-logo">退出登录</el-dropdown-item>
									</el-dropdown-menu>
								</el-dropdown>
							</div>
						</el-col>
					</el-row>
				</div>
			</el-header>
			<el-main>
				<router-view @bread="bread" :key="$route.fullPath"></router-view>
			</el-main>
		</el-container>
		<!-- 修改密码 -->
		<el-dialog title="修改密码" :visible.sync="passwordLog" width="37%"
			:close-on-click-modal="false" :close-on-press-escape="false" append-to-body center>
			<el-form  label-width="100px" class="demo-ruleForm" label-position="right">			
				<el-form-item label="旧密码" >							
					<el-input v-model="arrPassword.password"></el-input>
				</el-form-item>
				<el-form-item label="新密码" >
					<el-input v-model="arrPassword.password1"></el-input>
				</el-form-item>
				<el-form-item label="确认密码" >
					<el-input v-model="arrPassword.password2"></el-input>
				</el-form-item>
				
			</el-form>		
			<span slot="footer" class="dialog-footer">
				<el-button type="success" @click="verifyClick" >修改</el-button>
			</span>
		</el-dialog>
	</el-container>
	
</template>

<script>
	import oSystem from "@/js/system/index.js";
	import oLogin from "@/js/login/login.js";
	import Bread from "@/components/bread.vue"; //面包屑组件
	import Watermark from '@/libs/watermark.js';
	export default {
		components: {
			Bread,
		},
		computed: {
			userName() {
				let oUserInfo = JSON.parse(window.localStorage.getItem('user_info'))
				const name = oUserInfo.water_mark;
				return (name && name.length > 0) ? name : ''
			}
		},
		mounted() {
			this.breadd = this.$route.meta.title;
			Watermark.set(this.userName)
		},
		data() {
			return {
				menuList: [],
				breadd: "",
				//激活的地址链接
				menuPath: "",
				arrBreadData: {},
				isCollapse: false, //菜单关闭
				iconfont: "iconfont icon-shouqi", //图标按钮
				sHeadUrl: '',
				sNickName: '',
				oUserInfo: {},
				logo: "https://songyang-sy.oss-cn-huhehaote.aliyuncs.com/logo/%E8%8D%89%E8%8E%93.png",
				passwordLog:false,
				arrPassword:{
					password:'',
					password1:'',
					password2:'',
				}
			};
		},
		created() {
			this.getMenuApi();
			this.menuPath = window.sessionStorage.getItem("menuPath");

			this.oUserInfo = JSON.parse(window.localStorage.getItem('user_info'));
		},
		methods: {
			bread(data) {
				this.arrBreadData = data;
			},
			// 保存菜单激活状态
			subMenu(menuPath) {
				window.sessionStorage.setItem("menuPath", menuPath);
			},
			//头像点击事件
			handleCommand(command) {
				if (command === "logout") {
					//触发退出接口
					this.LogoutApi();
				}
				if (command === "clearCache") {
					//触发退出接口
					this.clearCache();
				}
				if(command === 'password'){
					this.passwordLog = true;
				}
			},
			/**
			 * 清理缓存
			 */
			clearCache() {
				oSystem.clearCache({}).then((res) => {
				});
			},

			//退出接口
			LogoutApi() {
				oLogin.Logout({}).then((res) => {
					Watermark.set('')
					// 跳转到登录
					this.$router.push({
						path: "/login"
					});
				});
			},
			//获取菜单列表接口
			getMenuApi() {
				let data = {};
				oSystem.getMenuList(data).then((res) => {
					this.menuList = res;
				});

			},
			/**
			 * 跳转手也
			 */
			toHome() {
				console.log(this.$router);
				this.$router.push({
					path: "/main"
				});
			},
			/**
			 * 修改密码
			 */
			verifyClick(){
				let data = this.arrPassword;
				oSystem.changePassword(data).then((res) => {
					this.$message.success('修改成功');
					this.passwordLog = false;
					this.$router.push({
						path: "/login"
					});
				});
				
			}
		},
	};
</script>

<style lang="less" scoped>
	.headerTop {
		display: flex;
		justify-content: flex-end;
	}

	.home {
		height: 100%;
	}

	.el-header {
		background-color: #ffffff;
	}

	.el-aside {
		background-color: #233646;

		.el-menu {
			border-right: none;
		}
	}

	.el-main {
		background-color: #f3f3f4;
	}

	.el-menu--collapse .el-submenu__title span {
		display: none;
	}
</style>
